import Sidebar from "@/components/Sidebar"
import AdminBar from "@/components/AdminBar"
import AdminToolbar from "@/components/AdminToolbar"

import { useState } from "react"

import useStyles from "./Admin.styles"

type AdminProps = {
  barTitle?: string
  children: any
}

const SidebarWithAdminBar = (props: Pick<AdminProps, "barTitle">) => {
  const { barTitle } = props

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const { classes, cx } = useStyles()

  const handleSidebarClose = () => {
    setSidebarOpen(false)
  }

  const handleToggleButtonClick = () => {
    setSidebarOpen(!sidebarOpen)
    setSidebarCollapsed(!sidebarCollapsed)
  }

  return (
    <>
      <Sidebar
        open={sidebarOpen}
        collapsed={sidebarCollapsed}
        onClose={handleSidebarClose}
      />
      <AdminBar
        className={cx(classes.adminBar, {
          [classes.adminBarSidebarCollapsed]: sidebarCollapsed,
        })}
        title={barTitle}
        onToggleButtonClick={handleToggleButtonClick}
      />
    </>
  )
}

const Admin = (props: AdminProps) => {
  const { barTitle, children } = props

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <SidebarWithAdminBar barTitle={barTitle} />
      <main className={classes.main}>
        <AdminToolbar />
        {children}
      </main>
    </div>
  )
}

export default Admin

import { alpha } from "@mui/material/styles"
import { makeStyles } from "tss-react/mui"

const drawerWidth = 280

const useStyles = makeStyles<
  void,
  | "selected"
  | "listItemText"
  | "listItemIcon"
  | "drawerPaper"
  | "drawer"
  | "listItem"
  | "listItemButton"
  | "listSubItemButton"
  | "listContainer"
  | "companyLogo"
  | "version"
>({
  name: "sidebar",
  uniqId: "qsejAe",
})((theme, _, classes) => {
  return {
    drawer: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    content: {
      position: "absolute",
      top: 0,
      left: 0,
      width: drawerWidth,
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    drawerCollapsed: {
      position: "absolute",
    },
    drawerCollapseOnHover: {
      position: "absolute",
      width: `calc(${theme.spacing(7)} + 1px)`,
      [`& .${classes.drawerPaper}`]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      [`& .${classes.listItem}.${classes.selected}:before`]: {
        left: `calc(${theme.spacing(7)} + 1px - 4px)`,
        transition: theme.transitions.create("left", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      [`& .${classes.listContainer}`]: {
        overflow: "hidden",
      },
      [`& .${classes.companyLogo}`]: {
        justifyContent: "flex-start",
      },
      [`& .${classes.version}`]: {
        textAlign: "start",
        width: `calc(${theme.spacing(7)} + 1px)`,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    logo: {
      justifyContent: "center",
    },
    listContainer: {
      flex: 1,
      overflowX: "hidden",
      overflowY: "auto",
    },
    listItem: {
      display: "block",
      [`&.${classes.selected}`]: {
        "&:before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: "calc(100% - 4px)",
          width: 4,
          height: "100%",
          backgroundColor: theme.palette.primary.main,
          transition: theme.transitions.create("left", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
        [`& > .${classes.listItemButton}`]: {
          position: "relative",
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
          color: theme.palette.primary.main,
          [`& .${classes.listItemText}, & .${classes.listItemIcon}`]: {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightSemiBold,
          },
        },
        [`& .${classes.listSubItemButton}`]: {
          backgroundColor: "transparent",
        },
      },
    },
    listItemButton: {
      // [`&.${classes.selected}`]: {
      //   position: "relative",
      //   backgroundColor: alpha(
      //     theme.palette.primary.main,
      //     theme.palette.action.selectedOpacity,
      //   ),
      //   color: theme.palette.primary.main,
      //   "&:before": {
      //     content: '""',
      //     position: "absolute",
      //     top: 0,
      //     left: "calc(100% - 4px)",
      //     width: 4,
      //     height: "100%",
      //     backgroundColor: theme.palette.primary.main,
      //     transition: theme.transitions.create("left", {
      //       easing: theme.transitions.easing.sharp,
      //       duration: theme.transitions.duration.enteringScreen,
      //     }),
      //   },
      //   [`& .${classes.listItemText}, & .${classes.listItemIcon}`]: {
      //     color: theme.palette.primary.main,
      //     fontWeight: theme.typography.fontWeightSemiBold,
      //   },
      // },
    },
    listSubItemButton: {},
    listItemArrow: {
      transition: theme.transitions.create(["transform"]),
    },
    listItemText: {
      color: theme.palette.text.secondary,
      textTransform: "capitalize",
    },
    listItemIcon: {
      position: "absolute",
      top: "50%",
      left: theme.spacing(2.5 + 1.25),
      transform: "translate(-50%,-50%)",
      color: theme.palette.text.secondary,
      transition: theme.transitions.create(["font-size"]),
    },
    companyLogo: {
      padding: theme.spacing(0.75, 1.25),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    version: {
      width: "100%",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    companyLogoSvg: {
      height: 36,
    },
    selected: {},
  }
})

export default useStyles

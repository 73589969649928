declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

import React from "react"

import { Toolbar, ToolbarProps } from "@mui/material"

const AdminToolbar = (props: ToolbarProps, ref: React.ForwardedRef<any>) => {
  const { classes: muiClasses, children, ...rest } = props

  return (
    <Toolbar
      classes={{
        ...muiClasses,
      }}
      ref={ref}
      {...rest}>
      {children}
    </Toolbar>
  )
}

const AdminToolbarWithRef = React.forwardRef(AdminToolbar)

export default AdminToolbarWithRef

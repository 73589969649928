import React, { useMemo } from "react"
import { bindActionCreators } from "redux"

import { storeAuthAction } from "@/store"

import {
  Avatar,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material"
import AppLink from "@/components/AppLink"

import LogoutIcon from "@mui/icons-material/Logout"
import PersonIcon from "@mui/icons-material/Person"

import { useAppDispatch, useAppSelector } from "@/hooks"
import { useTranslation } from "next-i18next"

import useStyles from "./LanguageMenuDropdown.styles"
import Image from "next/image"
import { useRouter } from "next/router"

const LanguageMenuDropdown = () => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>(null!)

  const { t, i18n } = useTranslation()
  const router = useRouter()
  const { classes } = useStyles()

  const flagImgName = useMemo(() => {
    const urlMap = {
      "zh-HK": "hk-flag.png",
      "zh-CN": "cn-flag.png",
      "en-US": "us-flag.png",
    }
    return urlMap[i18n.language as keyof typeof urlMap] || urlMap["zh-HK"]
  }, [i18n.language])

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLanguageChange = (locale: string) => () => {
    router.push(router.asPath, router.asPath, { locale: locale })
    window.NextPublic.lang = locale as any
    setOpen(false)
  }

  return (
    <>
      <IconButton
        className={classes.avatarButton}
        onClick={handleToggle}
        edge="start">
        <Image src={`/images/${flagImgName}`} alt="" width={24} height={24} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        transition
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 12 + 4],
            },
          },
        ]}
        disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "top right" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <MenuList dense className={classes.menuList}>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleLanguageChange("zh-HK")}
                      sx={{ textTransform: "capitalize" }}>
                      <ListItemIcon>
                        <Image
                          src="/images/hk-flag.png"
                          alt=""
                          width={24}
                          height={24}
                        />
                      </ListItemIcon>
                      {t("Traditional chinese")}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleLanguageChange("zh-CN")}
                      sx={{ textTransform: "capitalize" }}>
                      <ListItemIcon>
                        <Image
                          src="/images/cn-flag.png"
                          alt=""
                          width={24}
                          height={24}
                        />
                      </ListItemIcon>
                      {t("Simplified chinese")}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleLanguageChange("en-US")}
                      sx={{ textTransform: "capitalize" }}>
                      <ListItemIcon>
                        <Image
                          src="/images/us-flag.png"
                          alt=""
                          width={24}
                          height={24}
                        />
                      </ListItemIcon>
                      {t("English")}
                    </MenuItem>
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default LanguageMenuDropdown

import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles({
  name: "adminBar",
})(theme => {
  return {
    adminToolbar: {
      gap: theme.spacing(1.5),
    },
    toggleButton: {
      marginRight: theme.spacing(-1),
    },
  }
})

export default useStyles

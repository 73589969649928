import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles({
  name: "languageMenuDropdown",
})(theme => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    avatarButton: {
      borderRadius: "50%",
    },
    userInfo: {
      display: "flex",
      flexDirection: "column",
    },
    menuList: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    menuItem: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
    },
  }
})

export default useStyles

import React from "react"
import { bindActionCreators } from "redux"

import { storeAuthAction } from "@/store"

import {
  Avatar,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Grow,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material"
import AppLink from "@/components/AppLink"

import LogoutIcon from "@mui/icons-material/Logout"
import PersonIcon from "@mui/icons-material/Person"

import { useAppDispatch, useAppSelector } from "@/hooks"
import { useTranslation } from "next-i18next"

import useStyles from "./UserMenuDropdown.styles"

const UserMenuDropdown = () => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>(null!)

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const $s_authAction = React.useMemo(
    () => bindActionCreators(storeAuthAction, dispatch),
    [dispatch],
  )

  const $s_user = useAppSelector(state => state.auth.user)

  const { classes } = useStyles()

  const handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSignOutClick = () => {
    setOpen(false)
    $s_authAction.signOut()
  }

  return (
    <>
      <ButtonBase className={classes.avatarButton} onClick={handleToggle}>
        <Avatar
          sx={theme => ({ backgroundColor: theme.palette.primary.light })}>
          <PersonIcon />
        </Avatar>
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        transition
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 12 + 4],
            },
          },
        ]}
        disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "top right" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <List dense sx={{ paddingBottom: 0 }}>
                    <ListItem>
                      <div>
                        <Typography fontWeight={500}>
                          {$s_user?.userName}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="p"
                          color="textSecondary">
                          {$s_user?.isAdmin ? "Manager" : "Staff"}
                        </Typography>
                      </div>
                    </ListItem>
                  </List>
                  <Divider sx={{ marginTop: 0.5 }} />
                  <MenuList dense className={classes.menuList}>
                    <MenuItem
                      component={AppLink}
                      href="/my-profile"
                      className={classes.menuItem}
                      onClick={handleClose}>
                      <ListItemIcon>
                        <PersonIcon fontSize="small" />
                      </ListItemIcon>
                      {t("My profile")}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={handleSignOutClick}>
                      <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                      </ListItemIcon>
                      {t("Sign out")}
                    </MenuItem>
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default UserMenuDropdown

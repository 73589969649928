import { useEffect } from "react"

import { CircularProgress } from "@mui/material"

import { useRouter } from "next/router"
import { useAppSelector } from "@/hooks"

import useStyles from "./AuthGuard.styles"
import { useTranslation } from "next-i18next"

type AuthGuardProps = {
  children?: any
  disableRedirect?: boolean
}

const AuthGuard = (props: AuthGuardProps) => {
  const { disableRedirect, children } = props

  const { classes } = useStyles()
  const { i18n } = useTranslation()

  const router = useRouter()
  const $s_hasAuth = useAppSelector(state => !!state.auth.user?.id)
  const $s_userAuthChecking = useAppSelector(
    state => state.auth.userAuthChecking,
  )

  const unAuthPaths = [
    "/sign-in",
    "/en-us/sign-in",
    "/zh-hk/sign-in",
    "/zh-cn/sign-in",
  ]

  useEffect(() => {
    if (
      !$s_userAuthChecking &&
      !$s_hasAuth &&
      !unAuthPaths.includes((window?.location?.pathname || "").toLowerCase()) &&
      !disableRedirect
    ) {
      router.push(
        {
          pathname: "/sign-in",
          query: {
            ...router.query,
            redirect_path: window?.location?.pathname,
          },
        },
        undefined,
        {
          locale: i18n.language,
          shallow: true,
        },
      )
    } else if (
      unAuthPaths.includes(window?.location?.pathname) &&
      !$s_userAuthChecking &&
      $s_hasAuth
    ) {
      router.push(`/`)
    }
  }, [$s_hasAuth, $s_userAuthChecking, router.pathname, i18n.language])

  return $s_userAuthChecking ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : unAuthPaths.includes((window?.location?.pathname || "").toLowerCase()) ||
    disableRedirect ||
    $s_hasAuth ? (
    children
  ) : null
}

export default AuthGuard

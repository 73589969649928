import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles<void, "main">({
  name: "main",
  uniqId: "VY4iMp",
})((theme, _, classes) => {
  return {
    root: {
      display: "flex",
      justifyContent: "flex-end",
      position: "relative",
    },
    main: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      backgroundColor: theme.palette.grey["50"],
      minHeight: "100vh",
      width: `calc(100vw - ${280}px + 1px)`,
      transition: theme.transitions.create(["width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("md")]: {
        width: "100vw !important",
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
    },
    adminBar: {
      marginLeft: 280,
      width: `calc(100% - ${280}px)`,
      transition: theme.transitions.create(["width", "margin-left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        width: "100%",
      },
    },
    adminBarSidebarCollapsed: {
      marginLeft: `calc(${theme.spacing(7)} + 1px)`,
      width: `calc(100% - ${theme.spacing(7)} + 1px)`,
      transition: theme.transitions.create(["width", "margin-left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
        width: "100%",
      },
      [`& + .${classes.main}`]: {
        width: `calc(100vw - ${theme.spacing(7)} + 1px)`,
        transition: theme.transitions.create(["width"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
    },
  }
})

export default useStyles

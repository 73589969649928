import React from "react"

import {
  AppBar,
  Box,
  IconButton,
  Slide,
  Typography,
  useScrollTrigger,
} from "@mui/material"

import AdminToolbar from "@/components/AdminToolbar"

import UserMenuDropdown from "./components/UserMenuDropdown"
import LanguageMenuDropdown from "./components/LanguageMenuDropdown"

import MenuIcon from "@mui/icons-material/Menu"

import useStyles from "./AdminBar.styles"

export type AdminBarProps = {
  title?: string
  className?: string
  onToggleButtonClick: React.MouseEventHandler | undefined
}

const HideOnScroll = (props: {
  window?: () => Window
  children: React.ReactElement
}) => {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    threshold: 80 - 32,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const AdminBar = (props: AdminBarProps) => {
  const { className, title, onToggleButtonClick } = props

  const { classes, cx } = useStyles()

  const handleDrawerToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    onToggleButtonClick && onToggleButtonClick(event)
  }

  return (
    <HideOnScroll>
      <AppBar
        className={cx(!!className && className)}
        position="fixed"
        color="inherit">
        <AdminToolbar className={classes.adminToolbar}>
          <IconButton
            className={classes.toggleButton}
            onClick={handleDrawerToggle}
            edge="start">
            <MenuIcon />
          </IconButton>
          {title && <Typography>{title}</Typography>}
          <Box flexGrow={1} />
          <LanguageMenuDropdown />
          <UserMenuDropdown />
        </AdminToolbar>
      </AppBar>
    </HideOnScroll>
  )
}

export default AdminBar

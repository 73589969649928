import React from "react"

import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { commonConfig } from "@/utils/config"

import PeopleIcon from "@mui/icons-material/People"
import SettingsIcon from "@mui/icons-material/Settings"
import ControlCameraIcon from "@mui/icons-material/ControlCamera"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import ArticleIcon from "@mui/icons-material/Article"
import DashboardIcon from "@mui/icons-material/Dashboard"
import Groups2Icon from "@mui/icons-material/Groups2"
import WorkHistoryIcon from "@mui/icons-material/WorkHistory"
import HistoryIcon from "@mui/icons-material/History"
import { ReactComponent as VtlCompanyTextLogo } from "@/assets/svgs/vtl-company-text-logo.svg"
import { ReactComponent as VtlCompanyLogo } from "@/assets/svgs/vtl-company-logo.svg"

import AdminToolbar from "@/components/AdminToolbar"
import AppLink from "@/components/AppLink"

import { useRouter } from "next/router"
import { useCmsPermission, useIsMounted } from "@/hooks"
import { useTranslation } from "next-i18next"

import useStyles from "./Sidebar.styles"

type SidebarProps = {
  open?: boolean
  collapsed?: boolean
  onClose?: () => void
}

type MenuListProps = {
  menuLevel?: number
  openedSidebar?: boolean
  collapsedSidebar?: boolean
  isPrimaryMenu?: boolean
  menus: Partial<(typeof menus)[number]>[]
}

const menus = [
  {
    name: "Dashboard",
    Icon: DashboardIcon,
    path: "/dashboard",
    activePath: new RegExp("^/dashboard"),
    permissionName: "DASHBOARD_CHART",
  },
  {
    name: "Users",
    Icon: PeopleIcon,
    path: "/users",
    activePath: new RegExp("^/users"),
    permissionName: "LIST_USER",
  },
  {
    name: "Customers",
    Icon: Groups2Icon,
    path: "/customers",
    activePath: new RegExp("^/customers"),
    permissionName: "CUSTOMERS",
  },
  {
    name: "Reservation histories",
    Icon: HistoryIcon,
    path: "/reservation-histories",
    activePath: new RegExp("^/reservation-histories"),
    permissionName: "RESERVATION_HISTORY",
  },
  {
    name: "Backend operation histories",
    Icon: WorkHistoryIcon,
    path: "/backend-operation-histories",
    activePath: new RegExp("^/backend-operation-histories"),
    permissionName: "BACKEND_OPERATION_HISTORY",
  },
  {
    name: "Branch booking settings",
    Icon: SettingsIcon,
    activePath: new RegExp("^/branch-booking-settings"),
    permissionName: [
      "RESERVATION_OPENING_QUICK_CHANGE",
      "RESERVATION_DISABLE_QUICK_CHANGE",
      "RESERVATION_NOTICE_QUICK_CHANGE",
      "RESERVATION_SETTING_QUERY",
      "RESERVATION_OVERDUE_QUICK_CHANGE",
      "RESERVATION_BEFORE_TIME_QUICK_CHANGE",
      "RESERVATION_BABY_SEAT_QUICK_CHANGE",
      "RESERVATION_CALENDAR_DIVISION_TIME_QUICK_CHANGE",
    ],
    subMenus: [
      {
        name: "Reservation opening quick change",
        path: "/branch-booking-settings/reservation-opening-quick-change",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-opening-quick-change",
        ),
        permissionName: "RESERVATION_OPENING_QUICK_CHANGE",
      },
      {
        name: "Reservation disable quick change",
        path: "/branch-booking-settings/reservation-disable-quick-change",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-disable-quick-change",
        ),
        permissionName: "RESERVATION_DISABLE_QUICK_CHANGE",
      },
      {
        name: "Reservation notice quick change",
        path: "/branch-booking-settings/reservation-notice-quick-change",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-notice-quick-change",
        ),
        permissionName: "RESERVATION_NOTICE_QUICK_CHANGE",
      },
      {
        name: "Reservation overdue quick change",
        path: "/branch-booking-settings/reservation-overdue-quick-change",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-overdue-quick-change",
        ),
        permissionName: "RESERVATION_OVERDUE_QUICK_CHANGE",
      },
      {
        name: "Reservation before time quick change",
        path: "/branch-booking-settings/reservation-before-time-quick-change",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-before-time-quick-change",
        ),
        permissionName: "RESERVATION_BEFORE_TIME_QUICK_CHANGE",
      },
      {
        name: "Reservation baby seat quick change",
        path: "/branch-booking-settings/reservation-baby-seat-quick-change",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-baby-seat-quick-change",
        ),
        permissionName: "RESERVATION_BABY_SEAT_QUICK_CHANGE",
      },
      {
        name: "Reservation calendar division time quick change",
        path: "/branch-booking-settings/reservation-calendar-division-time-quick-change",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-calendar-division-time-quick-change",
        ),
        permissionName: "RESERVATION_CALENDAR_DIVISION_TIME_QUICK_CHANGE",
      },
      {
        name: "Reservation setting query",
        path: "/branch-booking-settings/reservation-setting-query",
        activePath: new RegExp(
          "^/branch-booking-settings/reservation-setting-query",
        ),
        permissionName: "RESERVATION_SETTING_QUERY",
      },
    ],
  },
  {
    name: "Booking app version controls",
    Icon: ControlCameraIcon,
    path: "/booking-app-version-controls",
    activePath: new RegExp("^/booking-app-version-controls"),
    permissionName: "BOOKING_APP_VERSION_CONTROL",
  },
  {
    name: "Booking remark",
    Icon: ArticleIcon,
    path: "/booking-remark",
    activePath: new RegExp("^/booking-remark"),
    permissionName: "BOOKING_REMARK",
  },
]

const MenuList = (props: MenuListProps) => {
  const {
    menuLevel = 0,
    menus,
    openedSidebar,
    collapsedSidebar,
    isPrimaryMenu = false,
  } = props

  const router = useRouter()

  const { canAccess } = useCmsPermission()
  const { t } = useTranslation()

  const [collapsedSubMenuMap, setCollapsedSubMenuMap] = React.useState<{
    [menuIndex: number]: boolean
  }>(() => {
    const collapsedMenuIndex = menus.findIndex(
      menu =>
        router.pathname.match(menu?.activePath || "") &&
        (menu.subMenus?.length || 0) > 0,
    )
    return collapsedMenuIndex > -1
      ? {
          [collapsedMenuIndex]: true,
        }
      : {}
  })

  const { classes, cx } = useStyles()

  const handleSubMenuToggle = (menuIndex: number) => () => {
    setCollapsedSubMenuMap(prevCollapsedSubMenuMap => ({
      ...prevCollapsedSubMenuMap,
      [menuIndex]: !prevCollapsedSubMenuMap[menuIndex],
    }))
  }

  const handleRouteChange = (url: string) => {
    const collapsedMenuIndex = menus.findIndex(
      menu =>
        url.match(menu.activePath || "") && (menu.subMenus?.length || 0) > 0,
    )
    setCollapsedSubMenuMap(
      collapsedMenuIndex > -1
        ? {
            [collapsedMenuIndex]: true,
          }
        : {},
    )
  }

  React.useEffect(() => {
    if (isMounted()) {
      handleRouteChange(router.pathname)
    }
  }, [router.pathname])

  const isMounted = useIsMounted()

  return (
    <List disablePadding={!isPrimaryMenu}>
      {menus.map((menu, menuIndex) => {
        const MenuIcon = menu?.Icon
        const hasSubMenu = (menu!.subMenus?.length || 0) > 0

        return (
          canAccess(menu.permissionName as any) && (
            <ListItem
              className={cx(classes.listItem, {
                [classes.selected]: !!router.pathname.match(
                  menu?.activePath || "",
                ),
              })}
              key={menuIndex}
              disablePadding>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: openedSidebar ? "initial" : "center",
                  px: 2.5,
                  paddingLeft:
                    2.5 * 2 + 2.5 + 2.5 * (isPrimaryMenu ? 0 : menuLevel - 1),
                }}
                classes={{
                  root: cx(
                    classes.listItemButton,
                    !isPrimaryMenu && classes.listSubItemButton,
                  ),
                }}
                component={menu?.path ? AppLink : "div"}
                href={menu?.path}
                onClick={
                  hasSubMenu ? handleSubMenuToggle(menuIndex) : undefined
                }>
                {!!MenuIcon && isPrimaryMenu ? (
                  <MenuIcon className={classes.listItemIcon} fontSize="small" />
                ) : (
                  <FiberManualRecordIcon
                    className={classes.listItemIcon}
                    sx={theme => ({
                      fontSize: !!router.pathname.match(menu?.activePath || "")
                        ? 12
                        : 6,
                      left: `${theme.spacing(
                        2.5 + 1.25 + 2.5 * (isPrimaryMenu ? 0 : menuLevel - 1),
                      )} !important`,
                    })}
                  />
                )}
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={t(menu!.name!)}
                  primaryTypographyProps={{
                    variant: "body2",
                  }}
                  sx={{ display: collapsedSidebar ? "none" : "initial" }}
                />
                {hasSubMenu && (
                  <KeyboardArrowLeftIcon
                    className={classes.listItemArrow}
                    color="inherit"
                    fontSize="small"
                    sx={{
                      transform: !!collapsedSubMenuMap[menuIndex]
                        ? "rotate(-90deg)"
                        : "rotate(0deg)",
                      display: collapsedSidebar ? "none" : "initial",
                    }}
                  />
                )}
              </ListItemButton>
              {hasSubMenu && (
                <Collapse
                  in={!!collapsedSubMenuMap[menuIndex] && !collapsedSidebar}
                  timeout="auto"
                  unmountOnExit>
                  <MenuList
                    menus={menu!.subMenus!}
                    collapsedSidebar={collapsedSidebar}
                    openedSidebar={openedSidebar}
                    menuLevel={menuLevel + 1}
                  />
                </Collapse>
              )}
            </ListItem>
          )
        )
      })}
    </List>
  )
}

const Sidebar = (props: SidebarProps) => {
  const { open, collapsed, onClose } = props

  const [rootHovered, setRootHovered] = React.useState(false)

  const { classes, cx } = useStyles()
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation()

  const isCollapsedOnHover = React.useMemo(
    () => !!collapsed && !isMdDown && !rootHovered,
    [collapsed, isMdDown, rootHovered],
  )

  const handleClose = () => {
    onClose && onClose()
  }

  return (
    <Drawer
      className={cx(classes.drawer, {
        [classes.drawerCollapseOnHover]: isCollapsedOnHover,
        [classes.drawerCollapsed]: collapsed,
      })}
      classes={{ paper: classes.drawerPaper }}
      variant={isMdDown ? "temporary" : "permanent"}
      open={!!open}
      onClose={handleClose}
      onMouseEnter={() => setRootHovered(true)}
      onMouseLeave={() => setRootHovered(false)}>
      <div className={classes.content}>
        <AdminToolbar className={classes.logo}>
          {!isCollapsedOnHover && t(commonConfig.DOCUMENT_TITLE)!}
        </AdminToolbar>
        <div className={classes.listContainer}>
          <MenuList
            menus={menus}
            collapsedSidebar={isCollapsedOnHover}
            openedSidebar={open}
            isPrimaryMenu
          />
        </div>
        <div className={classes.companyLogo}>
          {isCollapsedOnHover ? (
            <VtlCompanyLogo className={classes.companyLogoSvg} />
          ) : (
            <VtlCompanyTextLogo className={classes.companyLogoSvg} />
          )}
        </div>
        <Typography
          className={classes.version}
          variant="caption"
          color="textSecondary"
          fontWeight={500}
          component="div">
          {commonConfig.APP_VERSION}
        </Typography>
      </div>
    </Drawer>
  )
}

export default Sidebar

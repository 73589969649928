import _isFunction from "lodash/isFunction"

import { useTranslation } from "next-i18next"
import { Fragment, useEffect, useState } from "react"
import { useIsMounted } from "@/hooks"
import { NextSeoProps } from "next-seo"

import { NextSeo } from "next-seo"

type TitleFunc = (payload: { t: Function }) => string

type CoreProviderProps = {
  headParams?: {
    title?: string | TitleFunc
  } & Omit<NextSeoProps, "title">
  children: React.ReactNode
}

const CoreProvider = (props: CoreProviderProps) => {
  const { headParams, children } = props

  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    if (!isMounted()) return
    setLoading(true)
  }, [i18n.language])

  useEffect(() => {
    if (!isMounted()) return
    loading && setLoading(false)
  }, [loading])

  const isMounted = useIsMounted()

  return (
    <Fragment>
      <NextSeo
        {...headParams}
        title={
          headParams?.title
            ? _isFunction(headParams.title)
              ? headParams.title({ t })
              : headParams.title
            : ""
        }
      />
      {!loading && children}
    </Fragment>
  )
}

export default CoreProvider
